/// <reference types="@types/google.maps" />
import React, { useRef, useState } from "react";
import {
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonLoading,
  IonLabel,
  IonList,
  IonItem,
  IonIcon,
  IonModal,
  IonInput,
  IonTextarea,
  IonListHeader,
  IonRadio,
  IonRadioGroup,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  logoutUser,
  resetPassword,
  changeDisplayName,
} from "../firebaseconfig";
import {
  pencil,
  arrowBack,
  cloudUploadOutline,
  closeOutline,
} from "ionicons/icons";
import {
  checkIfFirstLogin,
  setBusinessInformation,
  getBusinessInformation,
} from "../firestoreconfig";
import { getLocation } from "../geolocation";
import { toast } from "../toast";
import { coords, MyLocation, BusInfo } from "../myTypes";
import AboutContent from "./aboutContent";
import "../theme/profile.css";
import { Categories, getBase64FromUrl } from "../config";
import { Loader } from "@googlemaps/js-api-loader";

const loader = new Loader({
  apiKey: "AIzaSyAkwRNpKQ8-EfvObWvWStBgL4B6Mj9qQ6E",
  libraries: ["places"],
});

loader.load();

const storeTypes: {
  [key: string]: string;
} = {
  physical_shipping: "Physical Retail Location & Online Shipping",
  physical: "Physical Retail Location Only",
  shipping: "Online Shipping Only",
};
const Profile: React.FC = () => {
  //one-time render stuff
  const [renderIndex, setRenderIndex] = useState<number>(0);

  if (renderIndex < 1) {
    var sessionToken = new google.maps.places.AutocompleteSessionToken();
    checkLogin();
    setRenderIndex(renderIndex + 1);
  }

  //maps
  const [locationString, setLocationString] = useState<string>("");
  const [locationCoords, setLocationCoords] = useState<coords>();
  const [locationID, setLocationID] = useState<string>("");
  const [lastLocationString, setLastLocationString] = useState<string>("");
  const [locationSuggest, setLocationSuggest] = useState<Array<MyLocation>>([]);

  // const Map = ReactMapboxGl({
  //   accessToken:
  //     "pk.eyJ1IjoibXJpZ2Fua3Bhd2FnaSIsImEiOiJjazl1OXk4c2swaTR4M2VtdXVrZmZjYnFtIn0.vnnewjnm1e6fNdWJcKoCfA",
  // });

  function geocode() {
    if (Math.abs(locationString.length - lastLocationString.length) > 1) {
      let str = locationString.replace(";", "");
      setLastLocationString(str);
      if (str.trim() !== "") {
        new google.maps.places.AutocompleteService().getPlacePredictions(
          {
            input: str,
            sessionToken: sessionToken,
            componentRestrictions: { country: "hk" },
          },
          (res) => {
          if(res){
            let response: Array<MyLocation> = [];
            res!.forEach((e: any) => {
              response.push({
                name: e.description,
                lat: undefined,
                lng: undefined,
                id: e.place_id,
              });
            });
            setLocationSuggest(response);
         
          }   
          }
        );
      }
    }
  }

  async function getCurrentLocation() {
    await getLocation().then(function (loc) {
      if (loc) {
        toast("Detected current location successfully.", 2000, "success");
        setLocationCoords(loc);
        reverseGeocode(loc);
      } else {
        toast("Could not detect location.", 2000, "danger");
      }
    });
  }

  async function reverseGeocode(loc: coords) {
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        let response: Array<MyLocation> = [];
        
        JSON.parse(this.responseText).results.forEach((e: any) => {
          response.push({
            name: e.formatted_address,
            lat: loc!.lat!,
            lng: loc!.lng!,
            id: e.place_id,
          });
        });
        setLocationSuggest(response);
      }
    };
    xhttp.open(
      "GET",
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
        loc?.lat +
        "," +
        loc?.lng +
        "&key=AIzaSyAkwRNpKQ8-EfvObWvWStBgL4B6Mj9qQ6E",
      true
    );
    xhttp.send();
  }

  // async function onlyGeocode() {
  //   let str = locationString.replace(";", "");
  //   if (str.trim() !== "") {
  //     var xhttp = new XMLHttpRequest();
  //     xhttp.onreadystatechange = function () {
  //       if (this.readyState === 4 && this.status === 200) {
  //         JSON.parse(this.responseText).features.forEach((e: any) => {
  //           setLocationCoords({
  //             lat: e.center[1],
  //             lng: e.center[0],
  //           });
  //         });
  //       }
  //     };
  //     xhttp.open(
  //       "GET",
  //       "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
  //         str +
  //         ".json?limit=1&country=HK&access_token=pk.eyJ1IjoibXJpZ2Fua3Bhd2FnaSIsImEiOiJjazl1OXk4c2swaTR4M2VtdXVrZmZjYnFtIn0.vnnewjnm1e6fNdWJcKoCfA",
  //       true
  //     );
  //     xhttp.send();
  //   }
  // }

  
  async function placeDetail(id: string) {
    new google.maps.places.PlacesService(document.createElement("div")).getDetails(
      {
        placeId: id,
        sessionToken: sessionToken,
        fields: ['geometry'],
      },
      (res) => {
        setLocationCoords({
          lat: res!.geometry!.location!.lat(),
          lng: res!.geometry!.location!.lng()
        });
        setLocationID(id);
        sessionToken = new google.maps.places.AutocompleteSessionToken();
      }
    );
  }


  //map-end

  const [isFirstLogin, setIsFirstLogin] = useState<boolean>(false);

  async function checkLogin() {
    const isExisting = await checkIfFirstLogin();
    setIsFirstLogin(!isExisting);
    if (isExisting) {
      const busInfo = await getBusinessInformation();
      if (busInfo) {
        setBusDescription(busInfo.description);
        setBusCat(busInfo.category);
        setLocationCoords(busInfo.location.coords);
        setLocationString(busInfo.location.name);
        setLocationID(busInfo.location.id ? busInfo.location.id : "")
        setTelNumber(busInfo.phone);
        setSummary(busInfo.summary);
        setBannerURL(busInfo.images.banner);
        setStoreImagesURL(busInfo.images.other);
        setWebsiteLink(busInfo.weblink);
        setStoreType(busInfo.type);
        getBase64FromUrl(busInfo.images.logo).then((r) => setLogoURL("" + r));
        getBase64FromUrl(busInfo.images.banner).then((r) =>
          setBannerURL("" + r)
        );
        let i = 0;
        let l: string[] = [];
        for (var x = 0; x < busInfo.images.other.length; x++) {
          // eslint-disable-next-line no-loop-func
          getBase64FromUrl(busInfo.images.other[x]).then((r) => {
            l.push(r + "");
            i++;
            if (i === busInfo.images.other.length) {
              setStoreImagesURL((storeImagesURL) => [...l]);
            }
          });
        }
        setCurrentBusInfo({
          description: busInfo.description,
          category: busInfo.category,
          locationCoords: {
            lat: busInfo.location.coords.lat,
            lng: busInfo.location.coords.lng,
          },
          locationString: busInfo.location.name,
          locationID: busInfo.location.id ? busInfo.location.id : "",
          phone: busInfo.phone,
          summary: busInfo.summary,
          images: {
            logo: busInfo.images.logo,
            banner: busInfo.images.banner,
            other: busInfo.images.other,
          },
          websiteLink: busInfo.weblink,
          type: busInfo.type,
        });
        console.log(locationID);
      }
    }
  }

  const [showNameChangeModal, setShowNameChangeModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [busy, setBusy] = useState<boolean>(false);
  const user = useSelector((state: any) => state.user.data);
  const history = useHistory();

  const [username, setUsername] = useState<string>(user.displayName);
  const [newName, setNewName] = useState<string>(user.displayName);

  const [busCat, setBusCat] = useState<string[]>([]);
  const [busDescription, setBusDescription] = useState<string>("");
  const [telNumber, setTelNumber] = useState<string>("");

  const [currentBusInfo, setCurrentBusInfo] = useState<BusInfo>({
    description: "",
    category: [],
    locationCoords: {
      lat: 0,
      lng: 0,
    },
    locationString: "",
    locationID: "",
    phone: "",
    summary: "",
    images: {
      logo: "",
      banner: "",
      other: [],
    },
    websiteLink: "",
    type: "",
  });

  async function logoutResponse() {
    await logoutUser();
    history.replace("/");
  }

  async function changePassword() {
    setBusy(true);
    const res = await resetPassword(user.email);
    if (res) {
    } else {
    }
    setBusy(false);
  }

  async function changeName() {
    setBusy(true);
    const res = await changeDisplayName(newName);
    if (res) {
      setShowNameChangeModal(false);
      setUsername(newName);
    } else {
    }
    setBusy(false);
  }

  async function saveBusinessInfo() {
    if (
      busCat === [] ||
      locationString === "" ||
      locationCoords == null ||
      busDescription === "" ||
      summary === "" ||
      logoURL === "" ||
      bannerURL === "" ||
      storeImagesURL.length === 0
    ) {
      toast("Please complete all mandatory fields.", 2000, "danger");
    } else {
      toast("Saving Information.", 2000, "primary");
      const response = await setBusinessInformation(
        locationString,
        locationCoords,
        locationID,
        busDescription,
        busCat,
        telNumber,
        isFirstLogin,
        summary,
        logoURL,
        bannerURL,
        storeImagesURL,
        websiteLink,
        storeType
      );
      if (response) {
        toast("Information saved.", 2000, "success");
        setIsFirstLogin(false);
        setShowInfoModal(false);
        setCurrentBusInfo({
          description: busDescription,
          category: busCat,
          locationCoords: locationCoords,
          locationString: locationString,
          locationID: locationID,
          phone: telNumber,
          summary: summary,
          images: {
            logo: logoURL,
            banner: bannerURL,
            other: storeImagesURL,
          },
          websiteLink: websiteLink,
          type: storeType,
        });
      } else {
        toast("Could not save information.", 2000, "danger");
      }
    }
  }

  const [showAboutModal, setShowAboutModal] = useState(false);
  const [storeType, setStoreType] = useState("physical_shipping");

  const [websiteLink, setWebsiteLink] = useState("");
  const [summary, setSummary] = useState("");

  const uploadLogoRef = useRef<HTMLInputElement>(null);
  const [logoURL, setLogoURL] = useState<string>("");
  const uploadBannerRef = useRef<HTMLInputElement>(null);
  const [bannerURL, setBannerURL] = useState<string>("");
  const uploadStoreImagesRef = useRef<HTMLInputElement>(null);
  const [storeImagesURL, setStoreImagesURL] = useState<Array<string>>([]);

  return (
    <div>
      <IonModal isOpen={showNameChangeModal} backdropDismiss={false}>
        <IonToolbar color="clear">
          <IonButton
            slot="start"
            onClick={() => setShowNameChangeModal(false)}
            fill="clear"
          >
            <IonIcon icon={arrowBack}></IonIcon>
          </IonButton>
          <IonTitle className="eina">Change Business Name</IonTitle>
        </IonToolbar>
        <IonContent className="ion-padding">
          <p>Enter your new business name below.</p>
          <IonList>
            <IonItem>
              <IonLabel position="stacked">Business Name</IonLabel>
              <IonInput
                value={newName}
                type="text"
                onIonChange={(e: any) => setNewName(e.target.value)}
              />
            </IonItem>
          </IonList>
          <IonButton onClick={changeName} color="primary">
            Confirm Name
          </IonButton>
        </IonContent>
      </IonModal>
      <IonModal isOpen={showAboutModal} backdropDismiss={false}>
        <IonToolbar color="clear">
          <IonButton
            slot="start"
            onClick={() => setShowAboutModal(false)}
            fill="clear"
          >
            <IonIcon icon={arrowBack}></IonIcon>
          </IonButton>
          <IonTitle className="eina">About</IonTitle>
        </IonToolbar>
        <IonContent className="ion-padding">
          <AboutContent />
        </IonContent>
      </IonModal>
      <IonModal isOpen={isFirstLogin || showInfoModal} backdropDismiss={false}>
        <IonToolbar color="clear">
          {!isFirstLogin && (
            <IonButton
              slot="start"
              onClick={() => setShowInfoModal(false)}
              fill="clear"
            >
              <IonIcon icon={arrowBack}></IonIcon>
            </IonButton>
          )}
          <IonTitle className="eina">Set Business Information</IonTitle>
        </IonToolbar>
        <IonContent className="ion-padding">
          {isFirstLogin ? (
            <p>
              Welcome to <b>EcoGo Business</b>! Fill in your business'
              information below to get started.
            </p>
          ) : (
            <p>Enter your new information below.</p>
          )}
          <IonList>
            <IonItem lines="none">
              <IonRadioGroup
                value={storeType}
                onIonChange={(e) => {
                  setStoreType(e.detail.value);
                  if (e.detail.value === "shipping") {
                    setLocationString("Online");
                    setLocationCoords({
                      lat: 0,
                      lng: 0,
                    });
                  } else {
                    setLocationString("");
                  }
                }}
              >
                <IonListHeader>
                  <IonLabel>Select Store Type</IonLabel>
                </IonListHeader>

                <IonItem>
                  <IonLabel>
                    Physical Retail Location &amp; Online Shipping
                  </IonLabel>
                  <IonRadio slot="start" value="physical_shipping" />
                </IonItem>

                <IonItem>
                  <IonLabel>Physical Retail Location Only</IonLabel>
                  <IonRadio slot="start" value="physical" />
                </IonItem>

                <IonItem>
                  <IonLabel>Online Shipping Only</IonLabel>
                  <IonRadio slot="start" value="shipping" />
                </IonItem>
              </IonRadioGroup>
            </IonItem>
            <IonItem className="grey-text">
              <br />
              If your store does not currently deliver, we highly suggest you
              expand online some time soon! EcoGo provides the customers, all
              you have to do is package up your products and ship!
              <br />
              <br />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Business Location</IonLabel>
              <IonInput
                value={locationString}
                onIonChange={(e: any) => {
                  setLocationString(e.target.value);
                  geocode();
                }}
                type="text"
                // onIonBlur={() => {
                //   if (locationString.trim() !== "" && locationCoords == null) {
                //     onlyGeocode();
                //   }
                // }}
                placeholder="Ex: S403, Block A, PMQ, 35 Aberdeen Street"
              />
            </IonItem>
            {locationSuggest.map((place, i) => (
              <IonItem
                button={true}
                detail={true}
                onClick={() => {
                  setLocationString(place.name);
                  if (place.lat && place.lng) {
                    setLocationCoords({
                      lat: place.lat,
                      lng: place.lng,
                    });
                  } else if (place.id){
                    placeDetail(place.id);
                  }
                }}
                key={i}
              >
                {place.name}
              </IonItem>
            ))}
            <IonItem lines="none" hidden={locationString.trim() === "Online"}>
              {locationCoords ? (
                // <Map
                //   // eslint-disable-next-line react/style-prop-object
                //   style={"mapbox://styles/mapbox/streets-v9"}
                //   containerStyle={{
                //     height: "200px",
                //     width: "100%",
                //   }}
                //   center={[
                //     Number(locationCoords.lng),
                //     Number(locationCoords.lat),
                //   ]}
                //   zoom={[15]}
                // >
                //   <Marker
                //     coordinates={[+locationCoords.lng!, +locationCoords.lat!]}
                //     anchor="bottom"
                //   >
                //     <img
                //       alt=""
                //       src={require("../images/markers/primary.png").default}
                //     />
                //   </Marker>
                // </Map>
                <iframe style={{width: '100%', border: '0'}} src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyAkwRNpKQ8-EfvObWvWStBgL4B6Mj9qQ6E&q=" + locationCoords.lat + "," + locationCoords.lng} title="Map"></iframe>
              ) : null}
            </IonItem>

            <IonItem
              button={true}
              detail={true}
              onClick={() => getCurrentLocation()}
              hidden={locationString.trim() === "Online"}
            >
              Use Current Location
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">
                Business Phone Number{" "}
                <span className="grey-text">(Optional)</span>
              </IonLabel>
              <IonInput
                value={telNumber}
                onIonChange={(e: any) => {
                  setTelNumber(e.target.value);
                }}
                type="tel"
                placeholder="Ex: +852 5555 5555"
              />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">
                Business Web Link <span className="grey-text">(Optional)</span>
              </IonLabel>
              <IonInput
                value={websiteLink}
                onIonChange={(e: any) => {
                  setWebsiteLink(e.target.value);
                }}
                type="text"
                placeholder="Ex: ecogohk.org"
              />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">
                One Sentence Business Summary
              </IonLabel>
              <IonInput
                value={summary}
                onIonChange={(e: any) => {
                  setSummary(e.target.value);
                }}
                type="text"
                placeholder="Ex: EcoGo helps you grow your business by creating an online presence."
              />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Business Description</IonLabel>
              <IonTextarea
                placeholder="Include store missions and how it is eco-friendly."
                value={busDescription}
                onIonChange={(e: any) => setBusDescription(e.target.value)}
              />
            </IonItem>

            <IonItem lines="none">
              <IonLabel>Business Tags</IonLabel>
              <IonSelect
                value={busCat}
                okText="Okay"
                multiple={true}
                interface="action-sheet"
                onIonChange={(e) => setBusCat(e.detail.value)}
              >
                {Categories.map((e, k) => {
                  return (
                    <IonSelectOption key={k} value={e.value}>
                      {e.text}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            </IonItem>
            <IonItem className="grey-text">
              <br />
              Please select all store tags relevant to your business. Selecting
              the incorrect store tags may lead to incorrect categorization.
              <br />
              <br />
            </IonItem>
            <IonItem lines="none">
              <h4 className="eina">
                Store Logo
                <label className="uploadBtn">
                  <IonButton
                    size="small"
                    shape="round"
                    onClick={() => {
                      if (uploadLogoRef !== null) {
                        uploadLogoRef!.current!.click();
                      }
                    }}
                  >
                    <IonIcon slot="end" icon={cloudUploadOutline}></IonIcon>
                    Upload Image
                  </IonButton>
                </label>
              </h4>
              <input
                type="file"
                accept=".jpg, .jpeg, .png, .gif"
                style={{ display: "none" }}
                ref={uploadLogoRef}
                onChange={(e) => {
                  var fr = new FileReader();
                  fr.onload = function (e) {
                    setLogoURL(this.result + "");
                  };
                  if (e!.target!.files![0])
                    fr.readAsDataURL(e!.target!.files![0]);
                }}
              />
            </IonItem>
            {logoURL && (
              <IonItem>
                <img className="boxedImage" alt="" src={logoURL} />
              </IonItem>
            )}

            <IonItem lines="none">
              <h4 className="eina">
                Store Banner
                <label className="uploadBtn">
                  <IonButton
                    size="small"
                    shape="round"
                    onClick={() => {
                      if (uploadBannerRef !== null) {
                        uploadBannerRef!.current!.click();
                      }
                    }}
                  >
                    <IonIcon slot="end" icon={cloudUploadOutline}></IonIcon>
                    Upload Image
                  </IonButton>
                </label>
              </h4>
              <input
                type="file"
                accept=".jpg, .jpeg, .png, .gif"
                style={{ display: "none" }}
                ref={uploadBannerRef}
                onChange={(e) => {
                  var fr = new FileReader();
                  fr.onload = function (e) {
                    setBannerURL(this.result + "");
                  };
                  if (e!.target!.files![0])
                    fr.readAsDataURL(e!.target!.files![0]);
                }}
              />
            </IonItem>
            {bannerURL && (
              <IonItem>
                <img className="boxedImage" alt="" src={bannerURL} />
              </IonItem>
            )}
            <IonItem lines="none">
              <h4 className="eina">
                Store Images
                <label className="uploadBtn">
                  <IonButton
                    size="small"
                    shape="round"
                    onClick={() => {
                      if (uploadStoreImagesRef !== null) {
                        uploadStoreImagesRef!.current!.click();
                      }
                    }}
                  >
                    <IonIcon slot="end" icon={cloudUploadOutline}></IonIcon>
                    Add Image
                  </IonButton>
                </label>
              </h4>
              <input
                type="file"
                accept=".jpg, .jpeg, .png, .gif"
                style={{ display: "none" }}
                ref={uploadStoreImagesRef}
                onChange={(e) => {
                  var fr = new FileReader();
                  fr.onload = function (e) {
                    let temp = storeImagesURL;
                    temp.push(this.result + "");
                    setStoreImagesURL((storeImagesURL) => [...temp]);
                  };
                  if (e!.target!.files![0])
                    fr.readAsDataURL(e!.target!.files![0]);
                }}
              />
            </IonItem>
          </IonList>
          {storeImagesURL.map((e, k) => (
            <IonItem key={k}>
              <img className="boxedImage" alt="" src={e} />
              <IonButton
                size="small"
                color="danger"
                shape="round"
                className="imageRemoveBtn"
                onClick={() => {
                  let temp = storeImagesURL;
                  temp.splice(k, 1);
                  setStoreImagesURL((storeImagesURL) => [...temp]);
                }}
              >
                <IonIcon icon={closeOutline} slot="start"></IonIcon>Remove
              </IonButton>
            </IonItem>
          ))}

          <IonButton
            color="primary"
            onClick={() => {
              saveBusinessInfo();
            }}
          >
            Save Information
          </IonButton>
        </IonContent>
      </IonModal>

      <IonLoading isOpen={busy} message={"Please wait."} spinner="dots" />
      <IonList inset={true}>
        <IonItem>
          <h2>{username}</h2>
          <IonButton slot="end" onClick={() => setShowNameChangeModal(true)}>
            <IonLabel>Edit</IonLabel>
            <IonIcon icon={pencil}></IonIcon>
          </IonButton>
        </IonItem>
        <IonItem>{user.email}</IonItem>
        <IonItem>
          <IonLabel className="ion-text-wrap">
            <h2>Store Type</h2>
            <p>{storeTypes[storeType]}</p>
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel className="ion-text-wrap">
            <h2>Location</h2>
            <p>{currentBusInfo.locationString}</p>
            <div hidden={currentBusInfo.locationString.trim() === "Online"}>
              {locationCoords ? (
                // <Map
                //   // eslint-disable-next-line react/style-prop-object
                //   style={"mapbox://styles/mapbox/streets-v9"}
                //   containerStyle={{
                //     height: "200px",
                //     width: "100%",
                //   }}
                //   center={[
                //     +Number(currentBusInfo!.locationCoords.lng),
                //     +Number(currentBusInfo!.locationCoords.lat),
                //   ]}
                //   zoom={[15]}
                // >
                //   <Marker
                //     coordinates={[
                //       +currentBusInfo.locationCoords.lng!,
                //       +currentBusInfo.locationCoords.lat!,
                //     ]}
                //     anchor="bottom"
                //   >
                //     <img
                //       className="boxedImage"
                //       alt=""
                //       src={require("../images/markers/primary.png").default}
                //     />
                //   </Marker>
                // </Map>
                <iframe style={{width: '100%', border: '0'}} src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyAkwRNpKQ8-EfvObWvWStBgL4B6Mj9qQ6E&q=" + currentBusInfo.locationCoords.lat + "," + currentBusInfo.locationCoords.lng} title="Map"></iframe>
              ) : null}
            </div>
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel className="ion-text-wrap">
            <h2>Phone Number</h2>
            <p>{currentBusInfo.phone}</p>
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel className="ion-text-wrap">
            <h2>Summary</h2>
            <p>{currentBusInfo.summary}</p>
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel className="ion-text-wrap">
            <h2>Description</h2>
            <p>{currentBusInfo.description}</p>
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel className="ion-text-wrap">
            <h2>Web Link</h2>
            <p>
              {currentBusInfo.websiteLink.trim().slice(0, 8) === "https://" ||
              currentBusInfo.websiteLink.trim().slice(0, 7) === "http://" ? (
                <a href={currentBusInfo.websiteLink.trim()}>
                  {currentBusInfo.websiteLink.trim().split("://")[1]}
                </a>
              ) : (
                <a href={"http://" + currentBusInfo.websiteLink.trim()}>
                  {currentBusInfo.websiteLink.trim()}
                </a>
              )}
            </p>
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel className="ion-text-wrap">
            <h2>Business Category</h2>
            <p>{currentBusInfo.category.join(", ")}</p>
          </IonLabel>
        </IonItem>

        <IonItem lines="none">
          <h4 className="eina">Store Logo</h4>
        </IonItem>
        {logoURL && (
          <IonItem>
            <img className="boxedImageSmall" alt="" src={logoURL} />
          </IonItem>
        )}

        <IonItem lines="none">
          <h4 className="eina">Store Banner</h4>
        </IonItem>
        {bannerURL && (
          <IonItem>
            <img className="boxedImageSmall" alt="" src={bannerURL} />
          </IonItem>
        )}
        <IonItem lines="none">
          <h4 className="eina">Store Images</h4>
        </IonItem>
          <IonItem>
        {storeImagesURL.map((e, k) => (
            <img key={k} className="boxedImageSmall" alt="" src={e} />
        ))}
        </IonItem>
        <IonItem
          button={true}
          onClick={() => {
            setShowInfoModal(true);
          }}
          detail={true}
        >
          <IonLabel className="comfortaa">Edit Information</IonLabel>
        </IonItem>
        <IonItem button={true} onClick={changePassword} detail={true}>
          <IonLabel className="comfortaa">Change Password</IonLabel>
        </IonItem>
        <IonItem button={true} onClick={logoutResponse} detail={true}>
          <IonLabel color="danger" className="comfortaa">
            Log Out
          </IonLabel>
        </IonItem>
        <IonItem onClick={() => setShowAboutModal(true)} button detail>
          About
        </IonItem>
        <IonItem
          onClick={() => window.open("mailto:ecogo.info@gmail.com", "_system")}
          button
          detail
        >
          Contact Support
        </IonItem>
      </IonList>
    </div>
  );
};

export default Profile;
