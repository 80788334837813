export const Categories = [
  { text: "Pet Supplies", value: "Pet Supplies" },
  { text: "Food & Beverages", value: "Food & Beverages" },
  { text: "Lifestyle & Home Products", value: "Lifestyle & Home Products" },
  { text: "Events", value: "Events" },
  { text: "Baby Products", value: "Baby Products" },
  { text: "Gifts", value: "Gifts" },
  { text: "Clothing & Cosmetics", value: "Clothing & Cosmetics" },
  { text: "Second Hand", value: "Second Hand" },
  { text: "Healthcare", value: "Healthcare" },
];

export async function getBase64FromUrl(url: string) {
  url =
    "https://firebasestorage.googleapis.com/v0/b/ecogo-business.appspot.com/o/" +
    url +
    "?alt=media&token=37f81d7c-71f6-47e8-8585-376ee625e638";
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
}

export const average = (arr: number[]) => {
  return arr.reduce((p, c) => p + c, 0) / arr.length;
};

export function removeEmptyItems(arr: string[]){
  let l:string[] = [];
  arr.map(e => {
    if(e !== "")
      l.push(e);
  });
  return l;
}

export function randomString(length: number) {
  return Math.round(
    Math.pow(36, length + 1) - Math.random() * Math.pow(36, length)
  )
    .toString(36)
    .slice(1);
}