import React, { useState } from "react";
import {
  IonContent,
  IonItem,
  IonGrid,
  IonButton,
  IonRow,
  IonCol,
  IonIcon,
  IonModal,
  IonToolbar,
  IonTitle,
  IonList,
  IonLabel,
  IonInput,
  IonAlert,
} from "@ionic/react";
import { db, addToQueueManually, proceedQueue } from "../firestoreconfig";
import { useSelector } from "react-redux";
import {
  serviceObj,
  Queue as QueueObj,
  AllQueuesArray,
  EmptyQueues,
  service,
  QueueDisplaySwitches,
} from "../myTypes";
import { getQueueData } from "../firestoreconfig";
import {
  add,
  arrowBack,
  push,
  caretDown,
  caretUp,
  playForward,
} from "ionicons/icons";
import { toast } from "../toast";

const Queue: React.FC = () => {
  //one-time render stuff
  const [renderIndex, setRenderIndex] = useState<number>(0);
  const user = useSelector((state: any) => state.user.data);
  const [prevServicesImage, setPrevServicesImage] = useState<serviceObj>({});

  const [allQueues, setAllQueues] = useState<AllQueuesArray>({});
  const [emptyQueues, setEmptyQueues] = useState<EmptyQueues>({});

  if (renderIndex < 1) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var liveQueueCheck = db
      .collection("vendors")
      .doc(user.uid)
      .onSnapshot(function (doc) {
        if (doc.exists) {
          var data: any = doc.data()!;
          setEmptyQueues({});
          Object.keys(data.serviceData).map((key: any, i: any) => {
            if (!data.serviceData[key].stock) data.serviceData[key].stock = 0;

            if (data.serviceData[key].available) {
              if (
                prevServicesImage[key]?.stock !== data.serviceData[key].stock &&
                data.serviceData[key].stock !== 0
              ) {
                if (
                  !(prevServicesImage[key]?.stock > data.serviceData[key].stock)
                ) {
                  setTimeout(
                    () => {
                      getQueue(key);
                    },
                    renderIndex < 1 ? 0 : 1000
                  );
                }
              } else if (data.serviceData[key].stock === 0) {
                let tempArr = emptyQueues;
                tempArr[key] = {
                  serviceData: data.serviceData[key],
                };
                setEmptyQueues({});
                setEmptyQueues(tempArr);
              }
            }
            let tempData = prevServicesImage;
            tempData[key] = data.serviceData[key];
            return setPrevServicesImage(tempData);
          });
        }
      });

    setRenderIndex(renderIndex + 1);
  }
  //

  async function getQueue(serviceID: number) {
    const response: QueueObj[] = await getQueueData(serviceID);
    if (response.length > 0) {
      let tempArr = allQueues;
      tempArr[serviceID] = response;
      setAllQueues({});
      setAllQueues(tempArr);

      if (!showCollapsibleQueue[serviceID]) {
        let tempObj = showCollapsibleQueue;
        tempObj[serviceID] = false;
        setShowCollapsibleQueue({});
        setShowCollapsibleQueue(tempObj);
      }
    }
  }

  const [addManuallyServiceData, setAddManuallyServiceData] = useState<
    service
  >();
  const [addManuallyServiceID, setAddManuallyServiceID] = useState<number>();
  const [addManuallyCustomerName, setAddManuallyCustomerName] = useState<
    string
  >("");
  const [showAddManuallyModal, setShowAddManuallyModal] = useState<boolean>(
    false
  );
  const [showCollapsibleQueue, setShowCollapsibleQueue] = useState<
    QueueDisplaySwitches
  >({});

  async function addManually() {
    if (addManuallyCustomerName.trim() === "") {
      toast("Customer Name is mandatory.", 2000, "danger");
    } else {
      const response = await addToQueueManually(
        addManuallyServiceID!,
        addManuallyServiceData!,
        addManuallyCustomerName
      );
      if (response) {
        toast("Successfully added customer to queue.", 2000, "success");

        setShowAddManuallyModal(false);
        setAddManuallyCustomerName("");
        setAddManuallyServiceData(undefined);
        setAddManuallyServiceID(undefined);
      } else {
        toast("Could not queue customer.", 2000, "danger");
      }
    }
  }

  const [showProceedQueueAlert, setShowProceedQueueAlert] = useState<boolean>(
    false
  );
  const [proceedQueueServiceID, setProceedQueueServiceID] = useState<string>();
  const [proceedQueueData, setProceedQueueData] = useState<QueueObj>();

  async function initProceedQueue() {
    const response = await proceedQueue(
      proceedQueueData!,
      proceedQueueServiceID!
    );
    if (response) {
      toast("Successfully updated queue.", 2000, "success");

      let tempObj = Object.assign({}, allQueues);
      tempObj[proceedQueueServiceID!] = tempObj[proceedQueueServiceID!].splice(
        1
      );
      setAllQueues({});
      setAllQueues(tempObj);

      setProceedQueueServiceID("");
      setShowProceedQueueAlert(false);
      setProceedQueueData(undefined);
    } else {
      toast("Could not proceed queue.", 2000, "danger");
    }
  }

  return (
    <IonContent>
      <IonAlert
        isOpen={showProceedQueueAlert}
        onDidDismiss={() => setShowProceedQueueAlert(false)}
        cssClass="alertBox"
        header={"Proceed Queue?"}
        message={
          "<b>Are you sure you want to proceed this queue?</b><br/><br/>The next customer in the queue will become eligible to avail your service."
        }
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
          },
          {
            text: "Yes",
            handler: () => {
              initProceedQueue();
            },
          },
        ]}
        backdropDismiss={false}
      />
      <IonModal isOpen={showAddManuallyModal} backdropDismiss={false}>
        <IonToolbar color="clear">
          <IonButton
            slot="start"
            onClick={() => {
              setShowAddManuallyModal(false);
              setAddManuallyCustomerName("");
              setAddManuallyServiceData(undefined);
              setAddManuallyServiceID(undefined);
            }}
            fill="clear"
          >
            <IonIcon icon={arrowBack}></IonIcon>
          </IonButton>
          <IonTitle className="eina">Manually Add Customer to </IonTitle>
        </IonToolbar>
        <IonContent className="ion-padding">
          <p>
            Enter the name of the customer you are adding to your queue. This
            name will be used for identification only. <br />
            <br />
            This feature should be used for customers who do not book a place
            via LocalQueue, but visit your place.
          </p>
          <IonList>
            <IonItem>
              <IonLabel position="floating">Customer Name</IonLabel>
              <IonInput
                value={addManuallyCustomerName}
                type="email"
                onIonChange={(e: any) =>
                  setAddManuallyCustomerName(e.target.value)
                }
              />
            </IonItem>
          </IonList>
          <IonButton onClick={() => addManually()} color="primary">
            Add to Queue
          </IonButton>
        </IonContent>
      </IonModal>
      <p>
        New feature coming soon!
      </p>
      {Object.keys(allQueues).map((key: any, i: number) =>
        allQueues[key][0] ? (
          <div
            key={key}
            className="ion-margin-bottom"
            style={{
              border: "1px solid #ddd",
              boxShadow: "2px 2px 3px 0px #aaa",
            }}
          >
            <IonItem lines="none">
              <h3 className="eina">{allQueues[key][0].serviceData.name}</h3>
              <p slot="end" style={{ marginTop: "22px", marginBottom: "10px" }}>
                {allQueues[key].length} in Queue
              </p>
            </IonItem>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonButton
                    expand="full"
                    onClick={() => {
                      setAddManuallyServiceData(allQueues[key][0].serviceData);
                      setAddManuallyServiceID(key);
                      setShowAddManuallyModal(true);
                    }}
                  >
                    <IonIcon icon={add} slot="start"></IonIcon>
                    Add Manually
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton
                    expand="full"
                    onClick={() => {
                      setProceedQueueData(allQueues[key][0]);
                      setProceedQueueServiceID(key);
                      setShowProceedQueueAlert(true);
                    }}
                    color="success"
                  >
                    <IonIcon icon={playForward} slot="start"></IonIcon>
                    Proceed Queue
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonItem
                    button={true}
                    detail={true}
                    color="warning"
                    lines="none"
                    onClick={() => {
                      let tempObj = Object.assign({}, showCollapsibleQueue);
                      tempObj[key] = !tempObj[key];
                      setShowCollapsibleQueue({});
                      setShowCollapsibleQueue(tempObj);
                    }}
                  >
                    <IonIcon
                      icon={showCollapsibleQueue[key] ? caretUp : caretDown}
                      slot="start"
                    ></IonIcon>
                    <p>{showCollapsibleQueue[key] ? "Hide " : "Show "}Queue</p>
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>
            <div hidden={!showCollapsibleQueue[key]}>
              {allQueues[key].map((q, i) => (
                <IonItem key={i}>
                  <p className="eina" slot="start">
                    {i + 1}
                  </p>
                  {q.isManual ? (
                    <p style={{ fontSize: "12px" }} slot="end">
                      {"Added On-Site "}
                      <IonIcon icon={push}></IonIcon>
                    </p>
                  ) : null}
                  {q.userData.name}
                </IonItem>
              ))}
            </div>
          </div>
        ) : null
      )}
      {Object.keys(emptyQueues).map((key: any, i: number) => (
        <div
          key={key}
          className="ion-margin-bottom"
          style={{
            border: "1px solid #ddd",
            boxShadow: "2px 2px 3px 0px #aaa",
          }}
        >
          <IonItem lines="none">
            <h3 className="eina">{emptyQueues[key].serviceData.name}</h3>
          </IonItem>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonButton
                  expand="full"
                  onClick={() => {
                    setAddManuallyServiceData(emptyQueues[key].serviceData);
                    setAddManuallyServiceID(key);
                    setShowAddManuallyModal(true);
                  }}
                >
                  <IonIcon icon={add} slot="start"></IonIcon>
                  Add Manually
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      ))}
    </IonContent>
  );
};

export default Queue;
