import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonGrid,
  IonCol,
  IonRow,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
} from "@ionic/react";
import React, { useState } from "react";
import "../theme/HomePage.css";

const Home: React.FC = () => {
  
  const [renderIndex, setRenderIndex] = useState<number>(0);

  if(renderIndex < 1){

    // Chatbox

  (function (d, s, id) {
    var fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    var js:any = d.createElement(s); js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
    fjs.parentNode!.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));

  //

    setRenderIndex(renderIndex + 1);
  }

  
  return (
    <IonPage className="pageContainer">
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle className="eina">EcoGo Business</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding container">
        <IonCard className="ion-text-center fix-width-card">
          <IonCardHeader>
            <img alt="" src={require("../images/icon.png").default} width={100} />
            <IonCardTitle className="eina">EcoGo Business</IonCardTitle>
            <IonCardSubtitle>
              Give power to your business by providing accurate information to Eco Friendly Consumers. 
            </IonCardSubtitle>
          </IonCardHeader>

          <IonCardContent>
            <IonButton routerLink="/tour" color="warning">
              Launch Tour
            </IonButton>
            <br/>

            <a href="https://play.google.com/store/apps/details?id=org.ecogohk.business">
              <img
                style={{width: "200px", maxWidth: "75%"}}
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              />
            </a>
          </IonCardContent>
        </IonCard>
        <IonCard className="ion-text-center fix-width-card">
          <IonCardHeader>
            <IonCardTitle>Start Using EcoGo Business</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            <IonGrid className="ion-text-center">
              <IonRow>
                <IonCol>
                  <IonButton routerLink="/login" color="primary">
                    Login
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton 
                  // routerLink="/signup" 
                  color="tertiary"
                  onClick={() => {window.location.href = "https://forms.gle/9FAzpLtEy9vGYFAo6"}}>
                    SignUp
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
        </IonCard>
        <IonCard className="ion-text-center fix-width-card">
          <IonCardHeader>
            <IonCardTitle>Pricing</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonGrid>
              <IonRow>
                <IonGrid>
                  <h2>
                    {/*<span
                      style={{ color: "red", textDecoration: "line-through" }}
                    >
                      <span className="eina" style={{ color: "black" }}>
                        Rs. 399
                      </span>{" "}
                      <span style={{ color: "black" }}>per month</span>
                    </span>
                    */}
                    <h1 className="eina" style={{ color: "green" }}>
                      Join for Free
                    </h1>
                    <h4>Limited Time Offer</h4>
                    <span className="small-text">
                      Subject to payment after offer period ends.
                    </span>
                  </h2>
                </IonGrid>
              </IonRow>
              <IonRow>
                <IonCol>
                  <br />
                  <img
                    alt=""
                    src={require("../images/misc/discount.png").default}
                    style={{ width: "50%" }}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
        </IonCard>
      </IonContent>
      <div id="fb-root"></div>
      <div id="fb-customer-chat" className="fb-customerchat" {...{"page_id": "100332755670240", "attribution": "biz_inbox"}}></div>
    </IonPage>
  );
};

export default Home;
