import {Geolocation} from "@ionic-native/geolocation";

export async function getLocation(){
    try{
        const position = await Geolocation.getCurrentPosition();
        return {
            lng: position.coords.longitude,
            lat: position.coords.latitude
        };
    }catch(e){
        return false;
    }
}
