import React, { useRef } from "react";
import { IonSlide, IonSlides, IonPage, IonContent, IonButton } from "@ionic/react";
import "../theme/slides.css";
import { useHistory } from "react-router";

const Slides: React.FC = () => {
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const history = useHistory();
  return (
    <IonPage>
      <IonContent>
        <IonSlides pager className="slides" ref={slidesRef}>
          <IonSlide className="signup slide">
            <img alt="" src={require("../images/tour/signup.png").default}/>
            
            <h2 className="eina">SignUp within Minutes</h2>
            <p>Register or claim your business in no time, and upload products your store has to offer for users to discover!</p>
            <IonButton color="light" onClick={() => slidesRef.current?.slideNext()}>
              Next
            </IonButton>
          </IonSlide>
          <IonSlide className="services slide">
            <img alt="" src={require("../images/tour/services.png").default}/>
            
            <h2 className="eina">Manage Products in a Breeze</h2>
            <p>Quickly add, manage and modify products through a range of powerful features.</p>
            <IonButton color="danger" onClick={() => slidesRef.current?.slideNext()}>
              Next
            </IonButton>
          </IonSlide>
          <IonSlide className="alerts slide">
            <img alt="" src={require("../images/tour/alerts.png").default}/>
            
            <h2 className="eina">Real-time Feedback from Users</h2>
            <p>Get feedback from consumers on your store as a whole, or on a specific product. </p>
            <IonButton color="warning" onClick={() => slidesRef.current?.slideNext()}>
              Next
            </IonButton>
          </IonSlide>
          <IonSlide className="queue slide">
            <img alt="" src={require("../images/tour/queue.png").default}/>
            
            <h2 className="eina">Manage User Analytics like Charm</h2>
            <p>Know which customers are regulars, and reward them accordingly!</p>
            <IonButton color="dark" onClick={() => slidesRef.current?.slideNext()}>
              Next
            </IonButton>
          </IonSlide>
          <IonSlide className="scan slide">
            <img alt="" src={require("../images/tour/scan.png").default}/>
            
            <h2 className="eina">Manage Customers like a Pro</h2>
            <p>Simply scan a user's QR code to check them in.</p>
            <IonButton color="primary" onClick={() => slidesRef.current?.slideNext()}>
              Next
            </IonButton>
          </IonSlide>
          <IonSlide className="customer slide">
            <img alt="" src={require("../images/tour/customer.png").default}/>
            
            <h2 className="eina">Customer Traction Guaranteed</h2>
            <p>Start with the new trend of EcoGo - giving eco-friendly shoppers the ability to explore new sustainable business near their location.</p>
            <IonButton color="tertiary" onClick={() => slidesRef.current?.slideNext()}>
              Next
            </IonButton>
          </IonSlide>
          <IonSlide className="welcome slide">
            <img alt="" src={require("../images/icon.png").default}/>
            
            <h2 className="eina">Start Today</h2>
            <p>What are you waiting for? Simply click a few buttons to start today!</p>
            <IonButton color="success" onClick={() => history.push("/")}>
              Start
            </IonButton>
          </IonSlide>
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};

export default Slides;
