import React, { useRef, useState } from "react";
import {
  IonContent,
  IonList,
  IonButton,
  IonIcon,
  IonModal,
  IonToolbar,
  IonTitle,
  IonItem,
  IonLabel,
  IonInput,
  IonTextarea,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonToggle,
  IonListHeader,
  IonRadio,
  IonRadioGroup,
  IonSelect,
  IonSelectOption,
  IonChip,
} from "@ionic/react";
import {
  add,
  arrowBack,
  pricetag,
  pencil,
  star,
  starHalf,
  cloudUploadOutline,
  closeOutline,
  fileTrayStackedOutline,
  fileTrayStacked,
} from "ionicons/icons";
import { toast } from "../toast";
import {
  getServices,
  updateServiceData,
  uploadImagesAndGetLinks,
} from "../firestoreconfig";
import { serviceObj } from "../myTypes";
import {
  Categories,
  average,
  removeEmptyItems,
  getBase64FromUrl,
} from "../config";

const Services: React.FC = () => {
  //one-time render stuff
  const [renderIndex, setRenderIndex] = useState<number>(0);

  if (renderIndex < 1) {
    updateServices();
    setRenderIndex(renderIndex + 1);
  }
  //
  const [showAddServiceModal, setShowAddServiceModal] =
    useState<boolean>(false);
  const [shouldEditService, setShouldEditService] = useState<boolean>(false);
  const [editServiceKey, setEditServiceKey] = useState<number>(-1);

  const [addServiceName, setAddServiceName] = useState<string>("");
  const [addServicePrice, setAddServicePrice] = useState<string>("");
  const [addServiceDescription, setAddServiceDescription] =
    useState<string>("");

  const [addServiceIsDiscounted, setAddServiceIsDiscounted] =
    useState<boolean>(false);
  const [addServiceOriginalPrice, setAddServiceOriginalPrice] =
    useState<string>("");

  const [serviceData, setServiceData] = useState<serviceObj>({});

  async function updateServices() {
    const data = await getServices();
    if (data) {
      setServiceData(data);
    }
  }

  async function addService() {
    let validationCondition =
      addServiceName.trim() === "" ||
      addServicePrice === "" ||
      stock === "" ||
      addServiceDescription.trim() === "" ||
      prodCat.length === 0 ||
      productImagesURL.length === 0 ||
      (addServiceIsDiscounted && addServiceOriginalPrice === "");
    if (validationCondition) {
      toast("All fields are mandatory.", 2000, "danger");
    } else {
      toast("Adding new product.", 2000, "primary");
      let prevData = serviceData;
      var newData: { [k: string]: any } = {};
      newData[Object.keys(prevData).length] = {
        type: productType,
        name: addServiceName,
        price: addServicePrice,
        stock: stock,
        description: addServiceDescription,
        available: true,
        isDiscounted: addServiceIsDiscounted,
        originalPrice: addServiceIsDiscounted ? addServiceOriginalPrice : 0,
        category: prodCat,
        variants: removeEmptyItems(variants),
        images: await uploadImagesAndGetLinks(productImagesURL),
      };
      let data = { ...prevData, ...newData };
      const response = await updateServiceData(data);
      if (response) {
        toast("Product added.", 2000, "success");
        setShowAddServiceModal(false);
        setProductType("item");
        setAddServiceName("");
        setAddServicePrice("");
        setStock("");
        setAddServiceDescription("");
        setProdCat([]);
        setVariants([]);
        setProductImagesURL([]);
        setAddServiceIsDiscounted(false);
        setAddServiceOriginalPrice("");
        setServiceData(data);
      } else {
        toast("Could not add product.", 2000, "danger");
      }
    }
  }

  async function editServices() {
    const response = await updateServiceData(serviceData);
    if (response) {
      updateServices();
      return true;
    } else {
      return false;
    }
  }

  async function toggleService(toggleType: boolean) {
    const response = await editServices();
    if (response) {
      toast(
        "Product is now " + (toggleType ? "" : "un") + "available.",
        2000,
        "success"
      );
    } else {
      toast("Could not make changes.", 2000, "danger");
    }
  }

  async function editServiceDetails() {
    let validationCondition =
      addServiceName.trim() === "" ||
      addServicePrice === "" ||
      stock === "" ||
      addServiceDescription.trim() === "" ||
      prodCat.length === 0 ||
      productImagesURL.length === 0 ||
      (addServiceIsDiscounted && addServiceOriginalPrice === "");
    if (validationCondition) {
      toast("All fields are mandatory.", 2000, "danger");
    } else {
      toast("Making changes.", 2000, "primary");

      serviceData[editServiceKey].type = productType;
      serviceData[editServiceKey].name = addServiceName;
      serviceData[editServiceKey].price = +addServicePrice;
      serviceData[editServiceKey].stock = +stock;
      serviceData[editServiceKey].description = addServiceDescription;

      serviceData[editServiceKey].isDiscounted = addServiceIsDiscounted
        ? addServiceIsDiscounted
        : false;
      serviceData[editServiceKey].originalPrice = +addServiceOriginalPrice;

      serviceData[editServiceKey].category = prodCat;
      serviceData[editServiceKey].variants = removeEmptyItems(variants);
      serviceData[editServiceKey].images = await uploadImagesAndGetLinks(
        productImagesURL
      );

      const response = await editServices();
      if (response) {
        toast("Product updated.", 2000, "success");
        setShowAddServiceModal(false);
        setShouldEditService(false);
        setEditServiceKey(-1);
        setEditOnlyStock(false);

        setProductType("item");
        setAddServiceName("");
        setAddServicePrice("");
        setStock("");
        setAddServiceDescription("");
        setProdCat([]);
        setVariants([]);
        setProductImagesURL([]);
        setAddServiceIsDiscounted(false);
        setAddServiceOriginalPrice("");
      } else {
        toast("Could not update Product.", 2000, "danger");
      }
    }
  }

  const [editOnlyStock, setEditOnlyStock] = useState<boolean>(false);

  async function initEditServiceDetails(key: number) {
    setProductType(serviceData[key].type);
    setAddServiceName(serviceData[key].name);
    setAddServicePrice("" + serviceData[key].price);
    setStock(serviceData[key].stock + "");
    setAddServiceDescription(serviceData[key].description);
    setAddServiceIsDiscounted(serviceData[key].isDiscounted);
    setAddServiceOriginalPrice("" + serviceData[key].originalPrice);
    setProdCat(serviceData[key].category);
    setVariants(serviceData[key].variants);
    let i = 0;
    let l: string[] = [];
    for (var x = 0; x < serviceData[key].images.length; x++) {
      // eslint-disable-next-line no-loop-func
      getBase64FromUrl(serviceData[key].images[x]).then((r) => {
        l.push(r + "");
        i++;
        if (i === serviceData[key].images.length) {
          setProductImagesURL((storeImagesURL) => [...l]);
        }
      });
    }

    setEditServiceKey(key);
    setShouldEditService(true);
    setShowAddServiceModal(true);
  }

  const [productType, setProductType] = useState<"item" | "service">("item");
  const [stock, setStock] = useState("");
  const [prodCat, setProdCat] = useState<string[]>([]);
  const uploadProductImagesRef = useRef<HTMLInputElement>(null);
  const [productImagesURL, setProductImagesURL] = useState<Array<string>>([]);
  const [variants, setVariants] = useState<Array<string>>([]);

  return (
    <IonContent>
      <IonModal isOpen={showAddServiceModal} backdropDismiss={false}>
        <IonToolbar color="clear">
          <IonButton
            slot="start"
            onClick={() => {
              setShowAddServiceModal(false);
              setEditOnlyStock(false);
              setShouldEditService(false);

              setProductType("item");
              setAddServiceName("");
              setAddServicePrice("");
              setStock("");
              setAddServiceDescription("");
              setProdCat([]);
              setVariants([]);
              setProductImagesURL([]);
              setAddServiceIsDiscounted(false);
              setAddServiceOriginalPrice("");
            }}
            fill="clear"
          >
            <IonIcon icon={arrowBack}></IonIcon>
          </IonButton>
          <IonTitle className="eina">
            {shouldEditService === false
              ? "Add New Product"
              : editOnlyStock === false
              ? "Edit Product"
              : "Edit Product Stock"}
          </IonTitle>
        </IonToolbar>
        <IonContent className="ion-padding">
          <p>
            {shouldEditService === false
              ? "Add a new product."
              : editOnlyStock === false
              ? "Edit the fields below to update your product."
              : "Edit this product's stock."}
          </p>
          <IonList>
            <IonItem hidden={editOnlyStock} lines="none">
              <IonRadioGroup
                value={productType}
                onIonChange={(e) => {
                  setProductType(e.detail.value);
                }}
              >
                <IonListHeader>
                  <IonLabel>Select Product Type</IonLabel>
                </IonListHeader>

                <IonItem lines="none">
                  <IonLabel>Item</IonLabel>
                  <IonRadio slot="start" value="item" />
                </IonItem>

                <IonItem lines="none">
                  <IonLabel>Service</IonLabel>
                  <IonRadio slot="start" value="service" />
                </IonItem>
              </IonRadioGroup>
            </IonItem>

            {/*<<IonItem>
            
            
              IonToggle
                checked={addServiceIsGeneral}
                onIonChange={(e) => {
                  setAddServiceIsGeneral(e.detail.checked);
                }}
              />
              <IonLabel className="ion-text-wrap">
                <h2>Is this a General Service?</h2>
                <p>
                  General services like <b>shopping</b> do not have a fixed
                  price or duration.
                </p>
              </IonLabel>
            </IonItem>
              */}
            <IonItem hidden={editOnlyStock}>
              <IonLabel position="stacked">Product Name</IonLabel>
              <IonInput
                value={addServiceName}
                type="text"
                onIonChange={(e: any) => setAddServiceName(e.target.value)}
                placeholder="Ex: Organic Soap"
              />
            </IonItem>
            <IonItem hidden={editOnlyStock}>
              <IonLabel position="stacked">Product Price (HK$)</IonLabel>
              <IonInput
                value={addServicePrice}
                type="number"
                onIonChange={(e: any) => setAddServicePrice(e.target.value)}
                placeholder="Ex: 39.99"
              />
            </IonItem>

            <IonItem hidden={editOnlyStock}>
              <IonToggle
                checked={addServiceIsDiscounted}
                onIonChange={(e) => {
                  setAddServiceIsDiscounted(e.detail.checked);
                }}
              />
              <IonLabel className="ion-text-wrap">
                <h2>Are you offering a Discount?</h2>
                <p>
                  Let your customers know if you are giving them a discount.
                </p>
              </IonLabel>
            </IonItem>

            <IonItem hidden={!addServiceIsDiscounted || editOnlyStock}>
              <IonLabel position="stacked">Original Price (HK$)</IonLabel>
              <IonInput
                value={addServiceOriginalPrice}
                type="number"
                onIonChange={(e: any) =>
                  setAddServiceOriginalPrice(e.target.value)
                }
                placeholder="Ex: 79.99"
              />
            </IonItem>

            <IonItem hidden={editOnlyStock}>
              <IonLabel position="stacked">Product Description</IonLabel>
              <IonTextarea
                value={addServiceDescription}
                onIonChange={(e: any) =>
                  setAddServiceDescription(e.target.value)
                }
                placeholder="Description of the product, how the product is eco-friendly, etc."
              />
            </IonItem>
            <IonItem lines="none">
              <IonLabel position="stacked">Current Product Stock</IonLabel>
              <IonInput
                value={stock}
                onIonChange={(e: any) => {
                  setStock(e.target.value);
                }}
                type="number"
                placeholder="Ex: 20"
              />
            </IonItem>
            <IonItem className="grey-text">
              <br />
              Limit the number of items that you can sell at once, in order to
              prevent selling more items than you can provide. This number will
              be automatically updated when customers purchase from your shop.
              <br />
              <br />
            </IonItem>

            <IonItem lines="none" hidden={editOnlyStock}>
              <IonLabel>Product Tags</IonLabel>
              <IonSelect
                value={prodCat}
                okText="Okay"
                multiple={true}
                interface="action-sheet"
                onIonChange={(e) => setProdCat(e.detail.value)}
              >
                {Categories.map((e, k) => {
                  if (["Events"].indexOf(e.value) === -1)
                    // Exclude from Product Tags
                    return (
                      <IonSelectOption key={k} value={e.value}>
                        {e.text}
                      </IonSelectOption>
                    );
                  else return null;
                })}
              </IonSelect>
            </IonItem>
            <IonItem className="grey-text" hidden={editOnlyStock}>
              <br />
              Please select all product tags relevant to this item. Selecting
              the incorrect product tags may lead to incorrect categorization.
              <br />
              <br />
            </IonItem>
            <span hidden={editOnlyStock}>
              <IonItem lines="none">
                <h4 className="eina">
                  Product Variants
                  <label className="uploadBtn">
                    <IonButton
                      size="small"
                      shape="round"
                      onClick={() => {
                        let temp = variants;
                        temp.push("");
                        setVariants((variants) => [...temp]);
                      }}
                    >
                      <IonIcon slot="end" icon={add}></IonIcon>
                      Add Variant
                    </IonButton>
                  </label>
                </h4>
              </IonItem>
              <IonItem lines="none" className="grey-text">
                Variants can be things such as colors or sizes.
                <br />
              </IonItem>
              {variants.map((e, k) => (
                <IonItem key={k}>
                  <IonInput
                    value={variants[k]}
                    type="text"
                    onIonChange={(e: any) => {
                      let temp = variants;
                      temp[k] = e.target.value;
                      setVariants((variants) => [...temp]);
                    }}
                    placeholder="Enter Variant"
                  />
                  <IonButton
                    size="small"
                    color="danger"
                    shape="round"
                    className="variantRemoveBtn"
                    onClick={() => {
                      let temp = variants;
                      temp.splice(k, 1);
                      setVariants((variants) => [...temp]);
                    }}
                  >
                    <IonIcon icon={closeOutline} slot="start"></IonIcon>Remove
                  </IonButton>
                </IonItem>
              ))}
            </span>
          </IonList>
          <span hidden={editOnlyStock}>
            <IonItem lines="none">
              <h4 className="eina">
                Product Images
                <label className="uploadBtn">
                  <IonButton
                    size="small"
                    shape="round"
                    onClick={() => {
                      if (uploadProductImagesRef !== null) {
                        uploadProductImagesRef!.current!.click();
                      }
                    }}
                  >
                    <IonIcon slot="end" icon={cloudUploadOutline}></IonIcon>
                    Add Image
                  </IonButton>
                </label>
              </h4>
              <input
                type="file"
                accept=".jpg, .jpeg, .png, .gif"
                style={{ display: "none" }}
                ref={uploadProductImagesRef}
                onChange={(e) => {
                  var fr = new FileReader();
                  fr.onload = function (e) {
                    let temp = productImagesURL;
                    temp.push(this.result + "");
                    setProductImagesURL((productImagesURL) => [...temp]);
                  };
                  if (e!.target!.files![0])
                    fr.readAsDataURL(e!.target!.files![0]);
                }}
              />
            </IonItem>

            {productImagesURL.map((e, k) => (
              <IonItem key={k}>
                <img className="boxedImage" alt="" src={e} />
                <IonButton
                  size="small"
                  color="danger"
                  shape="round"
                  className="imageRemoveBtn"
                  onClick={() => {
                    let temp = productImagesURL;
                    temp.splice(k, 1);
                    setProductImagesURL((productImagesURL) => [...temp]);
                  }}
                >
                  <IonIcon icon={closeOutline} slot="start"></IonIcon>Remove
                </IonButton>
              </IonItem>
            ))}
          </span>
          <IonButton
            onClick={() => {
              shouldEditService === false ? addService() : editServiceDetails();
            }}
            color="primary"
          >
            {editOnlyStock === false ? "Confirm Details" : "Confirm Stock"}
          </IonButton>
        </IonContent>
      </IonModal>

      <IonButton expand="full" onClick={() => setShowAddServiceModal(true)}>
        <IonIcon icon={add} slot="end"></IonIcon>
        Add Product
      </IonButton>
      <IonList>
        {Object.keys(serviceData)
          .reverse()
          .map((key: any, i) => (
            <IonCard
              key={key}
              className={serviceData[key].available ? "" : "disabledCard"}
            >
              <IonCardHeader>
                <IonItem lines="none">
                  <IonCardTitle>
                    <span className="small-text">
                      {serviceData[key].type.toUpperCase()}
                    </span>
                    <br />
                    {serviceData[key].name}
                  </IonCardTitle>
                  <IonButton
                    slot="end"
                    onClick={() => initEditServiceDetails(key)}
                  >
                    <IonLabel>Edit</IonLabel>
                    <IonIcon icon={pencil} slot="end"></IonIcon>
                  </IonButton>
                </IonItem>

                {serviceData[key].category.map((e, k) => (
                  <IonChip key={k} color="primary">
                    <IonLabel>{e}</IonLabel>
                  </IonChip>
                ))}

                <IonCardSubtitle>
                  <IonIcon icon={pricetag}></IonIcon>{" "}
                  {serviceData[key].isDiscounted ? (
                    <span
                      style={{ color: "black", textDecoration: "line-through" }}
                    >
                      <span style={{ color: "red" }}>
                        {"HK$ " + serviceData[key].originalPrice + " "}
                      </span>
                    </span>
                  ) : null}
                  {"HK$ " + serviceData[key].price}
                </IonCardSubtitle>
                <IonCardSubtitle>
                  <IonIcon icon={fileTrayStacked}></IonIcon>{" "}
                  <span>
                    {serviceData[key].stock + " left in stock"}
                    <IonButton
                      style={{
                        fontSize: "0.9em",
                        height: "25px",
                        marginLeft: "10px",
                        position: "relative",
                        top: "-5px",
                      }}
                      size="small"
                      color="warning"
                      onClick={() => {
                        setEditOnlyStock(true);
                        initEditServiceDetails(key);
                      }}
                    >
                      <IonLabel>Edit</IonLabel>
                      <IonIcon
                        icon={fileTrayStackedOutline}
                        slot="end"
                      ></IonIcon>
                    </IonButton>
                  </span>
                </IonCardSubtitle>
                {/*{serviceData[key].reviews ? (
                  <IonCardSubtitle>
                    {Array(Math.floor(average(serviceData[key].reviews))).fill(
                      <IonIcon icon={star} color="warning"></IonIcon>
                    )}
                    {average(serviceData[key].reviews) -
                      Math.floor(average(serviceData[key].reviews)) >=
                    0.4 ? (
                      <IonIcon icon={starHalf} color="warning"></IonIcon>
                    ) : null}
                  </IonCardSubtitle>
                ) : null}
                    */}
              </IonCardHeader>
              <IonCardContent>
                <p>
                  <b>{serviceData[key].description}</b>
                </p>
                <IonItem lines="none">
                  <IonToggle
                    checked={serviceData[key].available}
                    onIonChange={(e) => {
                      serviceData[key].available = e.detail.checked;
                      toggleService(e.detail.checked);
                    }}
                  />
                  <p>
                    Product {serviceData[key].available ? "" : "Not"} Available
                  </p>
                </IonItem>
              </IonCardContent>
            </IonCard>
          ))}
      </IonList>
    </IonContent>
  );
};

export default Services;
