import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

var firebaseConfigClient = {
	apiKey: "AIzaSyAN-rsoYnGYht77rRW7n_2DEIWDSvTuoPc",
	authDomain: "ecogollc.firebaseapp.com",
	projectId: "ecogollc",
	storageBucket: "ecogollc.appspot.com",
	messagingSenderId: "54455508367",
	appId: "1:54455508367:web:49cf89767796aa4e71bffa",
	measurementId: "G-QC219MBJ6F",
};


const firebaseClient = firebase.initializeApp(firebaseConfigClient, "client");
const clientDB = firebaseClient.firestore();

export { clientDB };
