import {
  IonPage,
  IonIcon,
  IonLabel,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonSegment,
  IonSegmentButton,
  IonFooter,
  IonContent,
  IonButton,
  IonModal,
  IonItem,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import React, { useState } from "react";
import {
  mailUnread,
  gridOutline,
  cubeOutline,
  personCircle,
  qrCode,
  arrowBack,
  camera,
} from "ionicons/icons";
import Profile from "../components/profile";
import Queue from "../components/queue";
import Alerts from "../components/alerts";
import Services from "../components/services";
import { db, getCustomerInfo } from "../firestoreconfig";
import { useSelector } from "react-redux";
import { toast } from "../toast";
import { usePhotoGallery, scanQRBase64Png } from "../camera";
import { Queue as QueueObj } from "../myTypes";

const DashboardContents: React.FC = () => {
  const [tabSection, SetTabSection] = useState<string>("profile");
  const user = useSelector((state: any) => state.user.data);
  const [newAlerts, setNewAlerts] = useState<boolean>(false);

  //one-time render stuff
  const [renderIndex, setRenderIndex] = useState<number>(0);

  if (renderIndex < 1) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var liveNotifCheck = db
      .collection("vendors")
      .doc(user.uid)
      .collection("notifications")
      .where("time", ">=", new Date(new Date().getTime() - 60000))
      .onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(function (change) {
          if (change.type === "added") {
            if (change.doc.data().type === "request") {
              toast(
                change.doc.data().userData.name +
                  " wants to queue up for " +
                  change.doc.data().service.name +
                  ".",
                10000,
                "dark",
                [
                  {
                    side: "end",
                    text: "View",
                    handler: () => {
                      SetTabSection("alerts");
                      setNewAlerts(false);
                    },
                  },
                ]
              );
            }
            if (change.doc.data().type === "rating") {
              toast(
                change.doc.data().userData.name +
                  " rated " +
                  change.doc.data().ratingValue +
                  " stars for " +
                  change.doc.data().service.name +
                  ".",
                5000,
                "dark",
                [
                  {
                    side: "end",
                    text: "View",
                    handler: () => {
                      SetTabSection("alerts");
                      setNewAlerts(false);
                    },
                  },
                ]
              );
            }
            setNewAlerts(true);
          }
        });
      });
    setRenderIndex(renderIndex + 1);
  }
  //

  const cam = usePhotoGallery();
  const [showQRModal, setShowQRModal] = useState<boolean>(false);
  const [customerQR, setCustomeQR] = useState<string>("");
  const [customerData, setCustomerData] = useState<QueueObj>();

  async function scanQR() {
    toast("Starting Camera.");
    const qrImage = await cam.takePhoto();
    if (qrImage) {
      const customerCode = await scanQRBase64Png(qrImage.base64String!);
      if (customerCode && customerCode !== "undefined") {
        setCustomeQR(customerCode.data);
      } else {
        toast("Could not detect code. Try typing the code.", 2000, "danger");
      }
    } else {
      toast("Could not take photo. Try typing the code.", 2000, "danger");
    }
  }

  async function initCustomerInfo() {
    if (customerQR.trim() !== "") {
      toast("Getting information.");
      const response = await getCustomerInfo(customerQR);
      if (response) {
        if ((response as QueueObj).isQueued) {
          setCustomerData(response as QueueObj);
        } else {
          toast(
            "Queue has already been fulfilled for this customer.",
            2000,
            "danger"
          );
        }
      } else {
        toast("Information not found. Please check the code.", 2000, "danger");
      }
    } else {
      toast("Please enter the customer's code.", 2000, "danger");
    }
  }

  return (
    <>
      <IonModal isOpen={showQRModal} backdropDismiss={false}>
        <IonToolbar color="clear">
          <IonButton
            slot="start"
            onClick={() => {
              setShowQRModal(false);
              setCustomeQR("");
              setCustomerData(undefined);
            }}
            fill="clear"
          >
            <IonIcon icon={arrowBack}></IonIcon>
          </IonButton>
          <IonTitle className="eina">Scan Customer Code</IonTitle>
        </IonToolbar>
        <IonContent className="ion-padding">
          <p>
            Scan your Customer's QR Code to quickly view their booking
            information.
          </p>
          <IonButton onClick={() => scanQR()} expand="full" color="tertiary">
            <IonIcon icon={camera} slot="start"></IonIcon>Scan
          </IonButton>

          <IonItem>
            <IonLabel position="floating">Customer Code</IonLabel>
            <IonInput
              type="text"
              placeholder="Type Code"
              onIonChange={(e: any) => setCustomeQR(e.target.value)}
              value={customerQR}
            />
          </IonItem>
          <br />

          <IonButton onClick={() => initCustomerInfo()}>
            Get Information
          </IonButton>
          <br />
          {customerData && (
            <div>
              <h3 className="eina">Customer Details</h3>
              <IonGrid>
                <IonRow>
                  <IonCol size="4">
                    <b>Name</b>
                  </IonCol>
                  <IonCol size="8">{customerData.userData.name}</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="4">
                    <b>Email</b>
                  </IonCol>
                  <IonCol size="8">{customerData.userData.email}</IonCol>
                </IonRow>
              </IonGrid>
              <h3 className="eina">Booking Details</h3>
              <IonGrid>
                <IonRow>
                  <IonCol size="4">
                    <b>Service</b>
                  </IonCol>
                  <IonCol size="8">{customerData.serviceData.name}</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="4">
                    <b>Due Payment</b>
                  </IonCol>
                  <IonCol size="8">Rs. {customerData.serviceData.price}</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="4">
                    <b>Booking Time</b>
                  </IonCol>
                  <IonCol size="8">
                    {customerData.time.toDate().toLocaleString()}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          )}
        </IonContent>
      </IonModal>
      <IonHeader translucent>
        <IonToolbar color="primary" className="ion-padding-right">
          <IonTitle className="eina">EcoGo Business</IonTitle>
          <IonButton
            color="clear"
            slot="end"
            onClick={() => setShowQRModal(true)}
            style={{marginRight: "20px"}}
            hidden={true}
          >
            <IonIcon icon={qrCode}></IonIcon>
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {tabSection === "queue" ? <Queue /> : null}
        {tabSection === "alerts" ? <Alerts /> : null}
        {tabSection === "services" ? <Services /> : null}
        {tabSection === "profile" ? <Profile /> : null}
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonSegment
            value={tabSection}
            onIonChange={(e: any) => SetTabSection(e.target.value!)}
          >
            <IonSegmentButton value="queue">
              <IonIcon icon={cubeOutline}></IonIcon>
              <IonLabel className="ion-text-capitalize">Orders</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="alerts">
              <IonIcon
                icon={mailUnread}
                color={
                  newAlerts && !(tabSection === "alerts") ? "danger" : "default"
                }
              ></IonIcon>
              <IonLabel className="ion-text-capitalize">Alerts</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="services">
              <IonIcon icon={gridOutline}></IonIcon>
              <IonLabel className="ion-text-capitalize">Products</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="profile">
              <IonIcon icon={personCircle}></IonIcon>
              <IonLabel className="ion-text-capitalize">Profile</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonFooter>
    </>
  );
};

const Dashboard: React.FC = () => (
  <IonPage>
    <DashboardContents />
  </IonPage>
);

export default Dashboard;

export { DashboardContents };
